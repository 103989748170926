import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  GetMemberRequest,
  GetMyMemberResponse,
} from '@wix/ambassador-members-ng-api/types';
import { withUser } from '../../../../contexts/User/withUser';
import { IUserProviderProps } from '../../../../contexts/User/UserProvider';
import { ICashierPaymentsApi } from '@wix/cashier-payments-widget';
import { withLocation } from '../../../../contexts/Location/withLocation';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import {
  Pages,
  useLocation,
} from '../../../../contexts/Location/LocationContext';
import { withChallengeData } from '../../../../contexts/ChallengeDataProvider/withChallengeData';
import { applyProviders } from '../../../../services/applyProviders';
import { PaymentWidget } from './PaymentWidget';
import { GeneralDataContext } from '../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { getOrderId } from '../../../../selectors/participants';
import { IChallengeDataProps } from '../../../../contexts/ChallengeDataProvider/helpers';
import { DeviceType } from '../../../../editor/types/common';
import { UserState } from '../../../../contexts/User/UserContext';
import { useChallengeData } from '../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useHttpClient } from '../../../../hooks/useHttpClient';

export interface IContainerProps {
  onApiReady(api: ICashierPaymentsApi): void;
  onWidgetLoaded(): void;
}

let requestInProgress = false;

type IPaymentContainerProps = IUserProviderProps &
  IContainerProps &
  ILocationProviderProps &
  IChallengeDataProps;

export const PaymentWidgetContainer: React.FC<IContainerProps> = applyProviders(
  (props: IPaymentContainerProps) => {
    const { appDefinitionId, language, isMobile, isViewer, isEditor } =
      useEnvironment();
    const {
      instanceId,
      instance,
      host,
      msid,
      defaultBIParams: { siteOwnerId },
    } = React.useContext(GeneralDataContext);
    const { isMyWalletInstalled } = useChallengeData();
    const challenge = props.challengeData?.challenge || {};
    const slug = challenge?.settings?.seo?.slug;
    const { query } = useLocation();
    const [currentMember, setCurrentMember] =
      React.useState<GetMyMemberResponse['member']>(null);
    const httpClient = useHttpClient(instance);

    React.useEffect(() => {
      async function init() {
        try {
          const resp = await httpClient.get<GetMyMemberResponse>(
            '/_api/members/v1/members/my',
            {
              params: {
                fieldsets: ['FULL'],
              } as GetMemberRequest,
            },
          );

          setCurrentMember(resp.data.member);
        } catch (error) {
          console.error(
            `Error on get current member:`,
            error?.response || error,
          );
        }
      }

      if (isViewer) {
        init();
      }
    }, [httpClient, isViewer]);

    if (!slug && isViewer) {
      return null;
    }

    const amount = challenge?.settings?.pricing?.singlePayment?.price?.amount;
    const currency =
      challenge?.settings?.pricing?.singlePayment?.price?.currency;

    const orderId = getOrderId(props.participant) || query.orderId;

    if (!props.user || (!orderId && isViewer)) {
      props.goToPage({
        pageId: Pages.Details,
        challengeId: slug,
      });

      return null;
    }

    if (!props.participant && !requestInProgress && isViewer) {
      requestInProgress = true;
    }

    return (
      <PaymentWidget
        currentMember={currentMember}
        siteOwnerId={siteOwnerId}
        appId={appDefinitionId}
        userId={props.user.id}
        instanceId={instanceId}
        instance={instance}
        host={host}
        domain={isEditor && 'https://editor.wix.com'}
        deviceType={isMobile ? DeviceType.Mobile : DeviceType.Desktop}
        msid={msid}
        locale={language}
        orderId={orderId}
        onApiReady={props.onApiReady}
        onWidgetLoaded={props.onWidgetLoaded}
        amount={amount}
        currency={currency}
        pendingOwnerApprove={() => {
          props.goToPage({
            pageId: Pages.Details,
            challengeId: slug,
            queryParams: {
              forceState: UserState.PAYMENT_PENDING,
            },
          });
        }}
        paymentComplete={() => {
          props.goToPage({
            pageId: Pages.ThankYou,
            challengeId: slug,
          });
        }}
        isMyWalletInstalled={isMyWalletInstalled}
      />
    );
  },
  [withChallengeData, withLocation, withUser],
);

PaymentWidgetContainer.displayName = 'PaymentWidgetContainer';
