import React from 'react';

import { Divider, TextField, Button, TextButton } from 'wix-ui-tpa';
import { Tag as TagIcon } from '@wix/wix-ui-icons-common/on-stage';
import {
  applyCouponToOrder,
  removeCouponFromOrder,
} from '@wix/ambassador-challenges-v1-participant/http';
import { ApplyCouponToOrderResponse } from '@wix/ambassador-challenges-v1-participant/types';
import { hasCoupons } from '@wix/ambassador-challenges-v1-challenge/http';

import { useTranslation, useExperiments, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import { useHttpClient } from '../../../../hooks/useHttpClient';
import { useGeneralData } from '../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useLocation } from '../../../../contexts/Location/LocationContext';
import { getOrderId } from '../../../../selectors/participants';
import { useUser } from '../../../../contexts/User/UserContext';
import { useChallengeData } from '../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { Challenges } from '../../../../editor/types/Experiments';

import { st, classes } from './PaymentCheckout.st.css';
import { ButtonNames } from '../../../../contexts/main/biInterfaces';
import { formatCurrency } from '@wix/challenges-web-library';
import uuid from 'uuid';

export interface PaymentCheckoutInfoProps {
  title: string;
  price: string;
  duration: string;
  showOneAppInfo(): void;
  onCouponApplied(coupon: ApplyCouponToOrderResponse): void;
}

export const PaymentCheckout: React.VFC<PaymentCheckoutInfoProps> = (props) => {
  const [hasCouponsForPrograms, setCouponsForPrograms] = React.useState(false);
  const { t } = useTranslation();
  const { instance } = useGeneralData();
  const httpClient = useHttpClient(instance);
  const { experiments } = useExperiments();
  const { challengeData } = useChallengeData();
  const isCouponsEnabled = experiments.enabled(Challenges.enableCoupons);
  const [appliedCoupon, setAppliedCoupon] = React.useState<
    ApplyCouponToOrderResponse & { couponCode: string }
  >(null);
  const currency =
    challengeData?.challenge?.settings?.pricing?.singlePayment?.price
      ?.currency || 'USD';
  const bi = useBi();

  React.useEffect(() => {
    async function init() {
      try {
        const resp = await httpClient.request(hasCoupons({}));
        setCouponsForPrograms(resp.data.result);
      } catch (error) {
        console.error(`Error on getting hasCoupons: ${error}`);
      }
    }

    isCouponsEnabled && init();
  }, [httpClient, isCouponsEnabled]);

  return (
    <section className={st(classes.root, {})}>
      <h1 className={classes.title}>{props.title}</h1>
      <time className={classes.duration}>{props.duration}</time>

      {hasCouponsForPrograms && !appliedCoupon && (
        <Coupons
          onCouponApplied={(coupon, couponCode) => {
            bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ApplyCoupon,
              }),
            );
            setAppliedCoupon({ ...coupon, couponCode });
            props.onCouponApplied(coupon);
          }}
        />
      )}
      {appliedCoupon && (
        <AppliedCoupon
          coupon={appliedCoupon}
          onRemoveCoupon={() => {
            bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.RemoveCoupon,
              }),
            );
            setAppliedCoupon(null);
            props.onCouponApplied(null);
          }}
        />
      )}

      <Divider className={classes.separator} />
      <dl className={classes.priceContainer}>
        <td className={classes.priceTitle}>{t('payment.page.total-price')}</td>
        <dd className={classes.priceValue}>
          {appliedCoupon?.total
            ? formatCurrency({ value: appliedCoupon?.total, currency })
            : props.price}
        </dd>
      </dl>
    </section>
  );
};

const ERRORS = {
  ERROR_COUPON_DOES_NOT_EXIST: 'coupons.form.error.doesnt-exist',
  payment_error: 'coupons.form.error.already-applied',
  ERROR_COUPON_IS_ALREADY_APPLIED: 'coupons.form.error.already-applied',
};

interface ICouponsProps {
  onCouponApplied(coupon: ApplyCouponToOrderResponse, couponCode: string): void;
}

const Coupons: React.VFC<ICouponsProps> = (props) => {
  const { instance } = useGeneralData();
  const httpClient = useHttpClient(instance);
  const { t } = useTranslation();
  const { query } = useLocation();
  const { participant } = useUser();
  const { challengeData } = useChallengeData();
  const orderId = getOrderId(participant) || query.orderId;
  const [couponCode, setCouponCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onFormSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const resp = await httpClient.request(
        applyCouponToOrder({
          participantId: participant?.id,
          orderId,
          challengeId: challengeData.challenge.id,
          couponCode,
          actionId: uuid(),
        }),
      );

      props.onCouponApplied(resp.data, couponCode);
    } catch (err) {
      console.log('error on applying coupon: ', err?.response);
      setError(
        ERRORS[err?.response?.data?.details?.applicationError?.code] ||
          'coupons.form.button.error-generic',
      );
    }

    setLoading(false);
  };

  return (
    <>
      <Divider className={classes.separator} />
      <form
        className={classes.coupons}
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit();
        }}
      >
        <TextField
          className={classes.couponsInput}
          label={t('coupons.form.input.label')}
          placeholder={t('coupons.form.input.placeholder')}
          value={couponCode}
          withClearButton={true}
          disabled={loading}
          error={!!error}
          newErrorMessage={true}
          errorMessage={t(error)}
          onClear={() => {
            setCouponCode('');
          }}
          onChange={(e) => {
            setCouponCode(e.target.value);
          }}
        />
        <Button
          disabled={!couponCode || loading}
          className={classes.couponsButton}
          type="submit"
        >
          {t('coupons.form.button.apply')}
        </Button>
      </form>
    </>
  );
};

interface IAppliedCouponProps {
  coupon: ApplyCouponToOrderResponse & { couponCode: string };
  onRemoveCoupon(): void;
}

const AppliedCoupon: React.VFC<IAppliedCouponProps> = ({
  coupon,
  onRemoveCoupon,
}) => {
  const { instance } = useGeneralData();
  const httpClient = useHttpClient(instance);
  const { t } = useTranslation();
  const { query } = useLocation();
  const { participant } = useUser();
  const { challengeData } = useChallengeData();
  const orderId = getOrderId(participant) || query.orderId;
  const [loading, setLoading] = React.useState(false);
  const currency =
    challengeData?.challenge?.settings?.pricing?.singlePayment?.price
      ?.currency || 'USD';

  const onRemoveClick = async () => {
    setLoading(true);
    try {
      await httpClient.request(
        removeCouponFromOrder({
          orderId,
          challengeId: challengeData.challenge.id,
          couponId: coupon.couponId,
          participantId: participant?.id,
          actionId: uuid(),
        }),
      );

      onRemoveCoupon();
    } catch (err) {
      console.log('error on remove coupon: ', err?.response);
    }

    setLoading(false);
  };

  return (
    <div>
      <Divider className={classes.separator} />
      <div className={classes.couponAppliedContainer}>
        <span className={classes.couponCode}>
          <TagIcon />
          <span>{coupon.couponCode}</span>
        </span>
        <TextButton disabled={loading} onClick={onRemoveClick}>
          {t('coupons.form.button.remove')}
        </TextButton>
      </div>
      <Divider className={classes.separator} />
      <dl className={classes.couponsDiscountContainer}>
        <dt className={classes.couponsTitle}>
          {t('coupons.applied.subtotal')}
        </dt>
        <dd className={classes.couponsValue}>
          {formatCurrency({ value: coupon.subTotal, currency })}
        </dd>
      </dl>
      <dl className={classes.couponsDiscountContainer}>
        <dt className={classes.couponsTitle}>
          {t('coupons.applied.discount', { couponName: coupon.couponCode })}
        </dt>
        <dd className={classes.couponsValue}>
          {' '}
          -{formatCurrency({ value: coupon.discount, currency })}
        </dd>
      </dl>
    </div>
  );
};
